<template>
    <div class="postSlider" v-if="!loading">
        <div>
            <div class="sliderTitle">
                <span class="common-title">BLOG<!--In|Salabam--> </span>&nbsp;
                <span>- La scelta dello staff</span> 
                <!-- <span class="info"><SvgInfoBlue v-b-popover.hover.top="'La scelta dello staff'" /></span> -->
            </div>
        </div>
        <div class="slick">
            <Slick ref="slickBlog" :options="slickOptions">
                <div v-for="(post,index) in posts" :key="'slide-'+index" >
                    <div class="postPad">

                      <!-- questo era per i links "alla cancellara", che se ne sbattevano delle url di wordpress -->
                      <!--<router-link :to="{
                                    name: 'post',
                                    params: {
                                        postId: post.postId,
                                        slug: post.slug,
                                    }
                                }">-->

                      <!-- gestisco le url ai post lato be per matchare quelle di wp -->
                      <router-link :to="post.url">

                        <div
                            class="postPad__thumbnail"
                            :style="'background-image:url('+post.featuredMedia.src+');'"
                        >
                        </div>

                      </router-link>

                        <div class="postPad__content">
                            <div>
                                <div class="postPad__date">{{post.readableDate}}</div>
                                <div class="postPad__title">{{post.title}}</div>
                                <!--
                                <div class="postPad__excerpt" v-html="post.excerpt"></div>
                                -->
                            </div>
                            <!--
                            <div class="postPad__link">
                                <router-link :to="{ 
                                    name: 'post',
                                    params: {
                                        postId: post.postId,
                                        title: post.slug,
                                    }
                                }">
                                    Scopri di più
                                </router-link>
                            </div>
                            -->
                        </div>
                    </div>
                </div>
            </Slick>
            <span class="slick-arrow slickBlogPrev" :id="'slickBlogPrev'+_uid">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <span class="slick-arrow slickBlogNext" :id="'slickBlogNext'+_uid">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </div>
    </div>
    <div class="postSlider loading" v-else>
        <Spinner />
    </div>
</template>

<script>
  import Api from '../../api'
  import Spinner from '../atoms/spinner'
  import Slick from 'vue-slick'
  //import SvgInfoBlue from './../svg/info-blue'

  export default {
    name: 'post-slider',
    components: {
        Spinner,
        Slick,
        //SvgInfoBlue,
    },
    props:
    {
        category: Number,
        exclude: 
        {
            type: Array,
            default: function () { return [] }
        },
        maxSlidesToShow:
        {
            type: Number,
            default: 4
        }
    },
    data() {
        return {
            loading: true,
            posts: Array(),
            slickOptions: {
                //autoplay: (this.$config.guiSettings.autoplaySpeedPosts != 0),
                autoplaySpeed: this.$config.guiSettings.autoplaySpeedPosts,
                //vertical: true,
                //verticalSwiping: true,
                slidesToShow: Math.min(4, this.maxSlidesToShow),
                prevArrow: '#slickBlogPrev'+this._uid,
                nextArrow: '#slickBlogNext'+this._uid,
                adaptiveHeight:true,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            centerMode: false,
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            centerMode: false,
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            centerMode: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                        }
                    }
                ]
            },
        }
    },
    mounted()
    {
        let _this = this
            _this.loading = true

        Api.getPosts({
            category: _this.category,
        })
        .then((results) => {
            _this.exclude.forEach(function(id){
                //ora è un array, delete non va più bene
                //delete results.posts[id]
                results.posts = results.posts.filter( obj => obj.postId !== id)
            })
            _this.posts = results.posts
            _this.loading = false
        })
    },
    methods:{
    }
  }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .postSlider
    {
        //spostato in _postWp.scss
    }

</style>